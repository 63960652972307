<template>
    <div class="pay-success">
        <div class="top">
            <img src="../../assets/images/chenggong.png" class="pay-success-img" />
            <p>订单支付成功！</p>
        </div>
        <!-- 公众号二维码 -->
        <we-chat-public-q-r-code></we-chat-public-q-r-code>
        <div class="result-button">
            <router-link class="red" to="/ShopMallOrder" replace tag="p">查看订单</router-link>
            <router-link to="/home" replace tag="p">返回首页</router-link>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
        };
    },
    mounted() {},
    methods: {},
    computed: {},
    filters: {}
};
</script>

<style lang="scss" scoped>
.pay-success {
    * {
        box-sizing: border-box;
    }
    min-height: 100vh;
    width: 100vw;
    background: #f6f6f6;

    .top {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 0.28rem;
        height: 62vw;

        img {
            width: 40vw;
            margin-bottom: 0.4rem;
        }

        p {
            font-size: 0.28rem;
        }
    }
    .qr-code-box {
        margin: 0 0.24rem;
    }

    .result-button {
        font-size: 0.26rem;
        display: flex;
        justify-content: center;
        margin-top: 0.8rem;

        p {
            width: 2.38rem;
            height: 0.86rem;
            border: 1px solid #dcdcdc;
            text-align: center;
            line-height: 0.86rem;
            color: #8b8b8b;
        }

        .red {
            color: #e30011;
            border-color: #e30011;
            margin-right: 0.4rem;
        }
    }
}
</style>
